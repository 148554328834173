/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { FNOLService } from 'nn-capability-fnol';
import { useValidation } from 'gw-portals-validation-react';
import { ModalNextProvider } from '@jutro/components';
import moment from 'moment';
import { TranslatorContext } from '@jutro/locale';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import FNOLAddress from '../../components/Address/Address';
import AuthenticatedContext from '../../contexts/AuthenticatedContext/AuthenticatedContext';
import metadata from './FNOLQuickFlowPage.metadata.json5';
import messages from './FNOLQuickFlowPage.messages';
import styles from './FNOLQuickFlowPage.scss';

const RESPONSES_SEND_CORRECTLY = {
    title: messages.fnolQuickFlowSubmitSuccessfullTitle,
    message: messages.fnolQuickFlowSubmitSuccessfullBody,
    status: 'info',
    confirmButtonText: messages.fnolQuickFlowSubmitOkMessage
};

const ERROR_OCCURED_DATA = {
    title: messages.fnolQuickFlowSubmitErrorTitle,
    message: messages.fnolQuickFlowSubmitErrorBody,
    status: 'info',
    confirmButtonText: messages.fnolQuickFlowSubmitOkMessage
};

function FNOLQuickFlowPage(props) {
    let initialData = {};
    const partnerContext = useContext(PartnerContext);
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [quickFlowDTO, setQuickFlowDTO] = useState({});

    const { location, history } = props;

    const {
        authenticationData
    } = useContext(AuthenticatedContext);

    const redirectToHomePage = useCallback(() => {
        return history.push('/');
    }, [history]);

    const { state } = location;
    const [timeStampVisibleValue, setTimeStampVisibleValue] = useState(state.timeStampVisibleValue);

    if (!state) {
        redirectToHomePage();
    } else {
        initialData = state.initialData;
    }

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('FNOLQuickFlowPage');

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    useEffect(() => {
        const initialSearchDTO = viewModelService.create(
            {
                policyNumber: initialData.policyNumber,
                lossDate: initialData.lossDate
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.QuickFnolDTORequest'
        );

        initialSearchDTO.authRequestDetails = {
            ...authenticationData.authenticatedRequestData
        }

        initialSearchDTO.policyHolderAddress = {};
        initialSearchDTO.lossLocation = {};
        return setQuickFlowDTO(initialSearchDTO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty Array as we don't want this code to re-run

    const validateTimeOfLoss = useCallback(() => {
        if (quickFlowDTO && quickFlowDTO.value) {
            if (timeStampVisibleValue === undefined) return false;

            return timeStampVisibleValue
                ? quickFlowDTO.value.lossDate.hour
                : true;
        }
        return true;
    }, [quickFlowDTO, timeStampVisibleValue]);

    useEffect(() => {
        registerComponentValidation(validateTimeOfLoss);
    }, [registerComponentValidation, validateTimeOfLoss]);

    const onValueChange = useCallback((value, path) => {
        const updatedDTO = viewModelService.clone(quickFlowDTO);
        _.set(updatedDTO.value, path, value);
        setQuickFlowDTO(updatedDTO);
    }, [quickFlowDTO, viewModelService]);

    const performCall = () => {
        const callCenterNumber = partnerContext.partnerPhoneNumber(translator);
        window.location.href = `tel:${callCenterNumber}`;
    };

    const showInformationalPopup = useCallback((data) => {
        return ModalNextProvider.showAlert(data);
    }, []);

    const onSubmit = useCallback(() => {
        FNOLService.processQuickFlowRequest(quickFlowDTO.value, history, { partnerContext, translator }).then(
            (response) => {
                if (!response) return;
                if (response.successfullyRegistered) {
                    showInformationalPopup(RESPONSES_SEND_CORRECTLY);
                } else {
                    showInformationalPopup(ERROR_OCCURED_DATA);
                }
            }
        ).catch(() => {
            showInformationalPopup(ERROR_OCCURED_DATA);
        }).finally(() => {
            redirectToHomePage();
        });
    }, [quickFlowDTO.value, history, partnerContext, translator, showInformationalPopup, redirectToHomePage]);

    const providedStyleOrNoStyleOnMobile = useCallback((className) => {
        return isMobileDevice() ? null : className;
    }, [isMobileDevice]);

    const mobileOrRegularStyle = useCallback((mobileClassName, className) => {
        return isMobileDevice() ? mobileClassName : className;
    }, [isMobileDevice]);

    if (_.isEmpty(quickFlowDTO)) return (<div />);

    const overrideProps = {
        fnolQuickFlowPolicyType: {
            className: providedStyleOrNoStyleOnMobile('quickFlowPageHorizontal')
        },
        fnolQuickPageHeaderFirstLine: {
            className: mobileOrRegularStyle('phoneCenterAlignedWithMargin', 'centerAlignedNoMargin')
        },
        fnolQuickPageHeaderSecondLine: {
            className: mobileOrRegularStyle('phoneCenterAlignedWithMargin', 'centerAlignedNoMargin')
        },
        fnolQuickPageHeaderThirdLine: {
            className: mobileOrRegularStyle('phoneCenterAlignedWithMargin', 'centerAlignedNoMargin')
        },
        fnolQuickFlowPageContactDetailsFLNameContainer: {
            className: providedStyleOrNoStyleOnMobile('flexJustify')
        },
        fnolQuickFlowFirstName: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin')
        },
        fnolQuickFlowLastName: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin')
        },
        fnolQuickFlowDateOfBirth: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin'),
            maxDate: moment().toDate()
        },
        fnolQuickFlowPageContactDetailsPhoneEmailContainer: {
            className: providedStyleOrNoStyleOnMobile('flexJustify')
        },
        fnolQuickFlowPhoneNumber: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin')
        },
        fnolQuickFlowEmailAddress: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin')
        },
        fnolQuickFlowPolicyNumber: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin')
        },
        fnolQuickFlowLossDate: {
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin'),
            maxDate: moment().toDate(),
            timeStampVisibleValue: timeStampVisibleValue,
            setTimeStampVisibleValue: setTimeStampVisibleValue
        },
        fnolQuickFlowAddress: {
            onWriteValue: onValueChange,
            isCountryMandatory: true,
            isCityMandatory: true,
            isPostalCodeMandatory: true,
            isStreetNameMandatory: true,
            isStreetNumberMandatory: true
        },
        fnolQuickFlowInsuredInsuredAddressHeader: {
            visible: quickFlowDTO.value.policyType === 'Home'
        },
        fnolQuickFlowIsTheSameAsAlreadyProvided: {
            visible: quickFlowDTO.value.policyType === 'Home'
        },
        fnolQuickFlowLossAddress: {
            visible: quickFlowDTO.value.policyType === 'Home' && quickFlowDTO.value.addressAlreadyProvided === false,
            onWriteValue: onValueChange,
            isCountryMandatory: true,
            isCityMandatory: true,
            isPostalCodeMandatory: true,
            isStreetNameMandatory: true,
            isStreetNumberMandatory: true
        },
        fnolQuickFlowLicensePlate: {
            visible: quickFlowDTO.value.policyType === 'Car',
            className: mobileOrRegularStyle('phoneWidthWithMargin', 'halfWidthWithBottomMargin')
        },
        fnolQuickFlowHomeIsTheSameAsAlreadyProvided: {
            visible: quickFlowDTO.value.policyType === 'Home'
        },
        fnolQuickFlowWhereDidItHappen: {
            visible: !(quickFlowDTO.value.policyType === 'Home' && !!quickFlowDTO.value.homeWhereHappendAddressAlreadyProvided)
        },
        fnolQuickFlowSubmitButton: {
            disabled: !isComponentValid
        },
        fnolQuickFlowPageUrgentCallNumber: {
            content: translator(messages.fnolQuickFlowPageUrgentCallNumber, { phoneNumber: partnerContext.partnerPhoneNumber(translator) })
        },
        fnolQuickPageContactUs: {
            content: partnerContext.partnerUrgentAssistLabel(translator)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            address: FNOLAddress
        },
        resolveCallbackMap: {
            performCall,
            onValidate,
            onSubmit
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={quickFlowDTO}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

export default withViewModelService(FNOLQuickFlowPage);
