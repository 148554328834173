import _ from 'lodash';
import { ModalNextProvider } from '@jutro/components';

export function redirectToQuickFlow(history, data, timeStampVisibleValue) {
    return history.push({
        pathname: '/quick-flow',
        state: {
            redirectPath: '/',
            initialData: data,
            timeStampVisibleValue: timeStampVisibleValue
        }
    });
}

export function redirectToContactCallCenter(history, callCenterTitle, callCenterBody,
    shouldShowTryAgainButton, showPhoneCall, allowSaveAndResumeMessage = false) {
    return history.push({
        pathname: '/contact-call-center',
        state: {
            redirectPath: '/',
            notificationTitle: callCenterTitle,
            notificationBody: callCenterBody,
            showTryAgainButton: shouldShowTryAgainButton,
            showPhoneCall: showPhoneCall,
            allowSaveAndResumeMessage
        }
    });
}

export function redirectToAuthContactCallCenter(history, authenticationError = false) {
    return history.push({
        pathname: '/auth-call-center',
        state: {
            redirectPath: '/',
            authenticationRelationError: authenticationError
        }
    });
}

export function redirectToExternalWebsite(actionData, url) {
    return ModalNextProvider
        .showConfirm(actionData)
        .then((result) => {
            if (result === 'cancel') {
                return true;
            }
            window.location.replace(url);
            return true;
        }, _.noop);
}
